import React from "react";
import clsx from "clsx";
import {
  image,
  wrapper,
} from "styles/components/index/writing-section.module.scss";

export default function WritingSection({
  articleUrl,
  body,
  imageUrl,
  title,
}: WritingSectionType) {
  return (
    <div className={clsx("row", wrapper)}>
      <div className="col-lg-4">
        <img className={image} src={imageUrl} />
      </div>
      <div className="col-lg-8">
        <h3>{title}</h3>
        <p>{body}</p>
        <a target="_blank" href={articleUrl}>
          <strong>Read This Article →</strong>
        </a>
      </div>
    </div>
  );
}

type WritingSectionType = {
  articleUrl: string;
  body: string;
  imageUrl: string;
  title: string;
};
