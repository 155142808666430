import React from "react";
import clsx from "clsx";
import { wrapper } from "styles/pages/layout/page.module.scss";

export default function Page({
  children,
  className,
}: {
  children: React.ReactElement | React.ReactElement[];
  className?: string;
}) {
  return <div className={clsx(wrapper, className)}>{children}</div>;
}
