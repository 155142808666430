import * as React from "react";
import { Helmet } from "react-helmet";
import clsx from "clsx";
import ContactForm from "components/contact-form";
import WritingSection from "components/index/writing-section";
import Page from "components/layout/page";
import logoDesktopImage from "images/logo-green.png";
import logoMainImage from "images/logo-white.png";
import missionDrift1Image from "images/mission-drift-1.jpg";
import missionDrift2Image from "images/mission-drift-2.jpg";
import missionDrift3Image from "images/mission-drift-3.jpg";
import repositionDelegationImage from "images/reposition-delegation.jpg";
import repositionMindsetImage from "images/reposition-mindset.jpg";
import article1 from "files/john-reed-article-1-v2.pdf";
import article2 from "files/john-reed-article-2-v2.pdf";
import article3 from "files/john-reed-article-3-v2.pdf";
import articleDelegation from "files/john-reed-article-Delegation.pdf";
import mindsetArticle from "files/reposition-mindset-article-1.pdf";
import "normalize.css";
import {
  banner,
  content,
  contentColumn,
  contentWrapper,
  desktopImage,
  desktopImageWrapper,
  footer,
  sectionBackground,
  sectionBranding,
  sectionBrandingCentered,
  section1Background,
  section2Background,
  section3Background,
  section4Background,
  section5Background,
  sectionWrapper,
  snapScrollContainer,
  snapScrollSection,
} from "styles/pages/index.module.scss";

export default function IndexPage() {
  return (
    <>
      <Helmet>
        <title>John Reed</title>
        <link href="/favicon.ico" rel="shortcut icon" type="image/x-icon" />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.1/dist/css/bootstrap-grid.min.css"
          rel="stylesheet"
          crossOrigin="anonymous"
        />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.1/dist/css/bootstrap-utilities.min.css"
          rel="stylesheet"
          crossOrigin="anonymous"
        />
      </Helmet>
      <Page>
        <div
          className={clsx(
            "d-none d-md-inline-block position-fixed start-50",
            desktopImageWrapper
          )}
        >
          <img className={desktopImage} src={logoDesktopImage} />
        </div>
        <section className={sectionWrapper}>
          <div className={snapScrollContainer}>
            <div className={snapScrollSection}>
              <div className="row g-0">
                <div className={clsx("col-md-6", "order-md-1", banner)}>
                  <div className={sectionBranding}>
                    <img src={logoMainImage} />
                  </div>
                  <div
                    className={clsx(sectionBackground, section1Background)}
                  />
                </div>
                <div className={clsx("col-md-6", "order-md-0", contentColumn)}>
                  <div className={clsx("h-100 overflow-auto", contentWrapper)}>
                    <div className={clsx(content)}>
                      <h1>Thinker. Writer. Speaker.</h1>
                      <p>
                        As a businessman, advisor, consultant and coach John has
                        found that managers and organisations often get ‘stuck’.
                      </p>
                      <p>
                        Having led businesses turnarounds he understands the
                        importance of a mindset that sees the opportunity in the
                        midst of difficulties. John assists&nbsp;managers to
                        think and act in a way that helps organisations get
                        ‘unstuck’. He has reflected deeply on this challenge
                        and, in addition to his consulting work, he now speaks
                        and writes on topics related to delegation, mission
                        drift and the importance of building a ‘Reposition
                        Mindset’.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={sectionWrapper}>
          <div className={snapScrollContainer}>
            <div className={snapScrollSection}>
              <div className="row g-0">
                <div className={clsx("col-md-6", "order-md-1", banner)}>
                  <div className={sectionBranding}>
                    <img src={logoMainImage} />
                  </div>
                  <div
                    className={clsx(sectionBackground, section2Background)}
                  />
                </div>
                <div className={clsx("col-md-6", "order-md-0", contentColumn)}>
                  <div className={clsx("h-100 overflow-auto", contentWrapper)}>
                    <div className={clsx(content)}>
                      <h2>Biography</h2>
                      <p>
                        John was born in Africa in 1958, and has been muddling
                        his way through life ever since. The youngest son of a
                        famous political author and a former starlet of London’s
                        West End, John spent much of his childhood left to his
                        own devices in his father’s hotel. Here, he was happy to
                        allow life to happen to him.
                      </p>
                      <p>What changed?</p>
                      <p>
                        John was surprised by a deeply personal experience in
                        his teenage years, where Christianity became real to him
                        and irrevocably marked his motivations, his
                        relationships, his career, and indeed, the entirety of
                        his life’s journey.
                      </p>
                      <p>
                        John was married at twenty-four, had two lovely
                        daughters by thirty, moved them between fifteen homes by
                        forty, and had four careers by fifty. Trained as an
                        educator, John moved on to work in commerce, and had the
                        privilege of rebuilding several troubled businesses.
                      </p>
                      <p>
                        Always given to thinking deeply, John now dedicates a
                        significant portion of his time to writing and speaking
                        about life, work, and God. He hopes to help others see
                        their own unique qualities and their capacity to move
                        forward.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={sectionWrapper}>
          <div className={snapScrollContainer}>
            <div className={snapScrollSection}>
              <div className="row g-0">
                <div className={clsx("col-md-6", "order-md-1", banner)}>
                  <div className={sectionBranding}>
                    <img src={logoMainImage} />
                  </div>
                  <div
                    className={clsx(sectionBackground, section3Background)}
                  />
                </div>
                <div
                  className={clsx(
                    "col-md-6",
                    "order-md-0",
                    "overflow-auto",
                    contentColumn
                  )}
                >
                  <div className={clsx("h-100 overflow-auto", contentWrapper)}>
                    <div className={clsx(content)}>
                      <h2>Writings</h2>
                      <WritingSection
                        articleUrl={mindsetArticle}
                        body="The ‘Reposition Mindset’ helps managers to think and act
                        in a way that delivers improved outcomes. The following
                        pages help the reader to apply this mindset to their own
                        particular management challenges."
                        imageUrl={repositionMindsetImage}
                        title="The Reposition Mindset"
                      />

                      <WritingSection
                        articleUrl={article1}
                        body="This article, the first part of a three part series,
                        focuses on the slow and imperceptible drift that
                        eventually changes the purpose of a Christian
                        organisation. This article is aptly titled ‘The Silent
                        Killer’."
                        imageUrl={missionDrift1Image}
                        title="Mission Drift | Part 1 - The Silent Killer"
                      />

                      <WritingSection
                        articleUrl={article2}
                        body="This article, the second part of a three part series,
                        focuses on how to identify signs of the slow and imperceptible
                        drift that eventually changes the purpose of a Christian organisation.
                        This article is aptly titled ‘What to watch out for’."
                        imageUrl={missionDrift2Image}
                        title="Mission Drift | Part 2 - What to watch out for"
                      />

                      <WritingSection
                        articleUrl={article3}
                        body="This article, the third part of a three part series,
                        focuses on intercepting the slow and imperceptible drift that
                        eventually changes the purpose of a Christian organisation.
                        This article is aptly titled ‘Intercepting Mission Drift’."
                        imageUrl={missionDrift3Image}
                        title="Mission Drift | Part 3 - Intercepting Mission Drift"
                      />

                      <WritingSection
                        articleUrl={articleDelegation}
                        body="What is the difference between delegation, suffocation
                        and abdication? Are there Biblical guidelines for
                        delegation? This article takes a closer look at
                        delegation from a Biblical perspective. We are
                        reminded that the Bible has much to say about how we
                        organise, who we recruit, how we delegate and how we
                        oversee what happens on the ground."
                        imageUrl={repositionDelegationImage}
                        title="Delegation - Lessons from Jethro 1300 BC"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={sectionWrapper}>
          <div className={snapScrollContainer}>
            <div className={snapScrollSection}>
              <div className="row g-0">
                <div className={clsx("col-md-6", "order-md-1", banner)}>
                  <div className={sectionBranding}>
                    <img src={logoMainImage} />
                  </div>
                  <div
                    className={clsx(sectionBackground, section4Background)}
                  />
                </div>
                <div
                  className={clsx(
                    "col-md-6",
                    "order-md-0",
                    "overflow-auto",
                    contentColumn
                  )}
                >
                  <div className={clsx("h-100 overflow-auto", contentWrapper)}>
                    <div className={clsx(content)}>
                      <h2>Talks</h2>
                      <p>
                        <strong>The Reposition Mindset</strong>
                      </p>
                      <p>
                        Peter Drucker, the highly regarded and respected
                        management consultant, observed that “Most of what we
                        call management consists of making it difficult for
                        people to get work done”. The outcome is that
                        organisations get ‘stuck’.
                      </p>
                      <p>
                        The Reposition Mindset’ is a model that helps managers
                        think and act in a way that helps organisation’s get
                        ‘unstuck’. John’s presentation on ‘The Reposition
                        Mindset’ facilitates the development of a collective
                        management mindset and the adoption of a common
                        management language, which enables managers to improve
                        the position of the organisation they manage.
                      </p>
                      <p>
                        <a href="#contact">Contact John</a>
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>Mission Drift</strong>
                      </p>
                      <p>
                        Mission Drift is a pressing problem for not for profit
                        organisations that exist for a particular purpose.
                        John’s focus is on Christian organisations and the
                        mission drift problem. He speaks about mission drift as
                        ‘silent killer’ which takes an organisation away from
                        its original purpose, ever so slowly, and small step by
                        small step. More importantly, he helps the audience to
                        better appreciate what needs to be done to prevent and
                        intercept mission drift.
                      </p>
                      <p>
                        <a href="#contact">Contact John</a>
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        <strong>
                          Delegation, Abdication or Suffocation – You Choose!
                        </strong>
                      </p>
                      <p>
                        In 1350BC Moses was overwhelmed with management
                        challenges, to the point of not being able to cope. In
                        this context, Jethro provide advice and assistance that
                        had a dramatic and favourable impact on Moses and all
                        the people that Moses impacted. Jethro’s advice
                        consisted of timeless truths about delegation which have
                        become the foundation of modern management theory. John
                        builds on Jethro’s advice and provides managers with
                        deep insights about the distinction between delegation,
                        abdication and suffocation.
                      </p>
                      <p>Contact John</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={sectionWrapper}>
          <div className={snapScrollContainer}>
            <div className={snapScrollSection}>
              <div className="row g-0 w-100">
                <div className={clsx("col-md-6", "order-md-1", banner)}>
                  <div className={sectionBrandingCentered}>
                    <img src={logoMainImage} />
                  </div>
                  <div
                    className={clsx(
                      "d-none d-md-block",
                      sectionBackground,
                      section5Background
                    )}
                  />
                </div>
                <div className={clsx("col-md-6", "order-md-0", contentColumn)}>
                  <div className={clsx("h-100 overflow-auto", contentWrapper)}>
                    <div className={clsx(content)}>
                      <h2>Contact</h2>
                      <p>
                        Get in touch with John for all speaking enquiries and
                        questions:
                      </p>
                      <ul>
                        <li>
                          <a href="https://www.facebook.com/johnreed.reposition">
                            <strong>Facebook</strong>
                          </a>
                        </li>
                        <li>
                          <a href="https://twitter.com/john_reposition">
                            <strong>Twitter</strong>
                          </a>
                        </li>
                        <li>
                          <a href="http://www.linkedin.com/in/johnreedofreposition">
                            <strong>LinkedIn</strong>
                          </a>
                        </li>
                      </ul>

                      <ContactForm id="contact" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className={footer}>
          <p>© 2021 Reposition Pty Ltd</p>
        </div>
      </Page>
    </>
  );
}
