import React, { useState } from "react";
import axios from "axios";
import Recaptcha from "react-recaptcha";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  button,
  emailWrapper,
  formWrapper,
  messageWrapper,
  nameWrapper,
} from "styles/components/contact-form.module.scss";

export default function ContactForm({ id }: { id: string }) {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  return (
    <>
      <div className={formWrapper} id={id}>
        <div>
          <div className={nameWrapper}>
            <input
              type="text"
              name="name"
              onChange={onChange(setName)}
              placeholder="Name"
              value={name}
            />
          </div>
          <div className={emailWrapper}>
            <input
              type="email"
              name="email"
              onChange={onChange(setEmail)}
              placeholder="Email"
              value={email}
            />
          </div>
          <div className={messageWrapper}>
            <textarea
              name="message"
              onChange={onChange(setMessage)}
              placeholder="Message"
              value={message}
            />
          </div>
        </div>
        <div>
          <Recaptcha
            sitekey={`${process.env.RECAPTCHA_SITE_KEY}`}
            size="invisible"
          />
        </div>
        <div>
          <button className={button} onClick={onSubmit} type="submit">
            Send
          </button>
        </div>
      </div>
      <ToastContainer />
    </>
  );

  function onChange(setFn: React.Dispatch<React.SetStateAction<string>>) {
    return function ({
      target,
    }:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>) {
      setFn(target.value);
    };
  }

  function onSubmit() {
    const payload = {
      name,
      email,
      message,
    };

    axios
      .post(
        "https://er7f7x2d7l.execute-api.ap-southeast-2.amazonaws.com/dev/email/send",
        payload
      )
      .then(function () {
        toast.success(
          "Thanks we've received your message and will be in touch!"
        );
        resetForm();
      })
      .catch(function (error) {
        console.log("error", error);
        toast.error(
          "There was an error with sending your message, hold up until I fix it. Thanks for waiting."
        );
      });
  }

  function resetForm() {
    setName("");
    setEmail("");
    setMessage("");
  }
}
